import React, { useEffect, useState } from 'react'
import { Tilt } from 'react-tilt'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'

import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import picme from '../../assets/images/picofme.png'
import adlob from "../../assets/images/adlob_login.png"
import epaperLive from "../../assets/images/toi_epaper.png"
import libsi from "../../assets/images/libsi.png"
import portfolio from "../../assets/images/portfolio.png"
import memorablemumbai from "../../assets/images/memorablemumbai.png"
import spotify from "../../assets/images/spotify.png"
import archive_epaper from "../../assets/images/archive_epaper.jpg"
import funPark from "../../assets/images/fun-park.png"
import aquibCms from "../../assets/images/aquib-cms.png"
import ecommerce from "../../assets/images/ecommerce.png"

function Work() {
  const projects = [
    {
      name: 'TOI ePaper (Times of India)',
      description: 'Built a high-traffic ePaper platform serving thousands of users daily.',
      tags: [
        { name: 'reactJs', color: 'blue-text-gradient' },
        { name: 'nodeJs', color: 'green-text-gradient' },
        { name: 'expressJs', color: 'pink-text-gradient' },
        { name: 'mongoDB', color: 'yellow-text-gradient' }
      ],
      image: epaperLive,
      source_code_link: 'https://epaper.indiatimes.com/'
    },
    {
      name: 'Libsi Markah E-commerce',
      description: 'Optimized data management and workflows to enhance operational efficiency.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'contextAPI', color: 'green-text-gradient' },
        { name: 'nodeJs', color: 'pink-text-gradient' },
        { name: 'mongoDB', color: 'yellow-text-gradient' }
      ],
      image: libsi,
      source_code_link: 'https://libsimarkah.com/sa-ar/women'
    },
    {
      name: 'Portfolio Website',
      description: 'A personal portfolio showcasing projects and achievements.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'nextJs', color: 'green-text-gradient' }
      ],
      image: portfolio,
      source_code_link: 'https://aquibyazdani.com'
    },
    {
      name: 'Memorable Mumbai',
      description: 'A comprehensive platform for travel bookings and information.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'contextAPI', color: 'green-text-gradient' }
      ],
      image: memorablemumbai,
      source_code_link: 'https://memorablemumbai.com'
    },
    {
      name: 'Spotify Clone',
      description: 'A music streaming platform inspired by Spotify.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'contextAPI', color: 'green-text-gradient' }
      ],
      image: spotify,
      source_code_link: 'https://spotify-yazdani.netlify.app'
    },
    {
      name: 'TOI ePaper-Archive (Times of India)',
      description: 'Developed an archival system for seamless access to past editions.',
      tags: [
        { name: 'reactJs', color: 'blue-text-gradient' },
        { name: 'predictiveAnalytics', color: 'green-text-gradient' },
        { name: 'contextAPI', color: 'pink-text-gradient' },
        { name: 'mongoDB', color: 'yellow-text-gradient' }
      ],
      image: archive_epaper,
      source_code_link: 'http://epaperarchives.timesgroup.com/'
    },
    {
      name: 'Response 4U',
      description: 'Designed an automated customer service platform for instant query resolution.',
      tags: [
        { name: 'reactJs', color: 'blue-text-gradient' },
        { name: 'nodeJs', color: 'green-text-gradient' },
        { name: 'expressJs', color: 'pink-text-gradient' },
        { name: 'aiChatbot', color: 'yellow-text-gradient' }
      ],
      image: adlob,
      source_code_link: '/'
    },
    {
      name: 'Amusement Park Booking',
      description: 'Online booking platform for amusement parks.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'nodeJs', color: 'green-text-gradient' },
        { name: 'mongoDB', color: 'pink-text-gradient' },
        { name: 'zustand', color: 'yellow-text-gradient' }
      ],
      image: funPark,
      source_code_link: 'https://amusement-park-new.netlify.app'
    },
    {
      name: 'CMS for Blogging',
      description: 'A content management system for blogging.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'nodeJs', color: 'green-text-gradient' },
        { name: 'mongoDB', color: 'pink-text-gradient' },
        { name: 'contextAPI', color: 'yellow-text-gradient' }
      ],
      image: aquibCms,
      source_code_link: 'https://cms.aquibyazdani.com'
    },
    {
      name: 'E-commerce Clone',
      description: 'A clone of an e-commerce platform with core functionalities.',
      tags: [
        { name: 'react', color: 'blue-text-gradient' },
        { name: 'redux', color: 'green-text-gradient' },
        { name: 'nodeJs', color: 'pink-text-gradient' }
      ],
      image: ecommerce,
      source_code_link: 'https://shoppe-aquib-yazdani.netlify.app'
    }
  ];
  const ProjectCard = ({
    index,
    name,
    description,
    tags,
    image,
    source_code_link,
  }) => {
    return (
      <motion.div variants={fadeIn('up', 'spring', index * 0.5, 0.75)}>
        <Tilt
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary tilt_wrapper p-5 rounded-2xl sm:w-[360px] w-full"
        >
          <div className="me_logo_container">
            <img
              width={'25px'}
              src={picme}
              alt="source code"
              className="w-1/2 h-1/2 object-contain"
            />
          </div>
          <div
            className="relative w-full h-[230px]"
            onClick={() => window.open(source_code_link)}
          >
            <img
              src={image}
              alt="project_image"
              width={'100%'}
              className="w-full h-full object-cover rounded-2xl"
            />
          </div>

          <div className="mt-5 relative-pos">
            <h3 className="header_card">{name}</h3>
            <p className="header_card_para mt-2">{description}</p>
          </div>

          <div className="hash_container mt-4 flex flex-wrap gap-2">
            {tags.map((tag, i) => (
              <p key={`${name}-${tag.name}`} className={'logo' + i}>
                #{tag.name}
              </p>
            ))}
          </div>
        </Tilt>
      </motion.div>
    )
  }
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])
  return (
    <>
      <div className="container portfolio-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['M', 'y', '', 'W', 'o', ' r', 'k']}
              idx={15}
            />
          </h1>
          {/* <p>
            I'm a very ambitious front-end developer looking for a role in an
            established IT company with the opportunity to work with the latest
            technologies on challenging and diverse projects.
          </p>
          <p align="LEFT">
            I'm quiet confident, naturally curious, and perpetually working on
            improving my chops one design problem at a time.
          </p>
          <p>
            If I need to define myself in one sentence that would be a techie person, a sports fanatic,
            photography enthusiast, and tech-obsessed!!!
          </p> */}
        </div>
        <div className="stage-container">
          <div className="projrct_card_container">
            {projects.map((project, index) => (
              <ProjectCard
                key={`project-${index}`}
                index={index}
                {...project}
              />
            ))}
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Work
