import { Route, Routes } from 'react-router-dom'
import './App.scss'
import Layout from './components/Layout'
import Home from './components/Home/Index'
import About from './components/About'
import Contact from './components/Contact'
import Work from './components/Work'
import NotFound from './components/NotFound/NotFound'
import Experience from './components/Experience'
// import Portfolio from './components/Portfolio'
// import Dashboard from './components/Dashboard'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Work />} />
          <Route path="/work-experience" element={<Experience />} />
          {/*  <Route path="/dashboard" element={<Dashboard />} />  */}
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
