import React, { useEffect, useState } from 'react'
import { Tilt } from 'react-tilt'
import { motion } from 'framer-motion'
import { fadeIn } from '../../utils/motion'

import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import picme from '../../assets/images/picofme.png'
import adlob from "../../assets/images/adlob_login.png"
import epaperLive from "../../assets/images/toi_epaper.png"
import libsi from "../../assets/images/libsi.png"
import portfolio from "../../assets/images/portfolio.png"
import memorablemumbai from "../../assets/images/memorablemumbai.png"
import spotify from "../../assets/images/spotify.png"
import archive_epaper from "../../assets/images/archive_epaper.jpg"
import funPark from "../../assets/images/fun-park.png"
import aquibCms from "../../assets/images/aquib-cms.png"
import ecommerce from "../../assets/images/ecommerce.png"

function Experience() {
  const experiences = [
    {
      title: 'Software Developer-II',
      company: 'Harns Technologies Pvt Ltd',
      duration: 'June 2021 - Present',
      description: [
        'Optimized system performance by 30% through the development of reusable components and an improved language management system.',
        'Led the frontend development for key projects, including Adlob and Industry 4.0, ensuring scalability and maintainability.',
        'Collaborated cross-functionally to integrate five distinct systems, increasing operational efficiency by 30%.',
        'Developed responsive web guides and CMS platforms for the Times of India, enhancing content accessibility and user engagement.',
        'Streamlined development workflows by creating custom functions for state management, reducing implementation time by 20%.'
      ],
    },
    {
      title: 'Web Developer',
      company: 'Smug Brands LLP',
      duration: 'Aug 2020 – May 2021',
      description: [
        'Developed dynamic and responsive web applications, including e-commerce platforms, ensuring cross-device compatibility and optimal performance.',
        'Worked closely with UX and backend teams to create seamless, user-centric experiences, leading to a 10% increase in customer satisfaction.',
        'Refactored legacy code and introduced modular design patterns, improving maintainability and speeding up development cycles.'
      ],
    }
  ];

  const ExperienceCard = ({ index, title, company, duration, description }) => {
    return (
      <motion.div variants={fadeIn('up', 'spring', index * 0.5, 0.75)}>
        <Tilt
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className="bg-tertiary tilt_wrapper p-5 rounded-2xl sm:w-[360px] w-full"
        >
          <div className="me_logo_container">
            <img
              width={'25px'}
              src={picme}
              alt="profile"
              className="w-1/2 h-1/2 object-contain"
            />
          </div>
          <div className="mt-5 relative-pos">
            <h3 className="header_card_exp">{title}</h3>
            <h4 className="header_card_company">{company}</h4>
            <p className="header_card_duration mt-2">{duration}</p>
            <ul className="header_card_para mt-2">
              {description.map((point, i) => (
             <>
                <li key={i}>{point}</li>
                <br/ >
            </>
              ))}
            </ul>
          </div>
        </Tilt>
      </motion.div>
    );
  };

  const [letterClass, setLetterClass] = useState('text-animate');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  return (
    <>
      <div className="container portfolio-page ">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['E', 'x','p', 'e', 'r', ' i', 'e','n','c','e']}
              idx={15}
            />
          </h1>
          <h2>Harns Technologies Pvt Ltd. / Smug Brands llp</h2>
        </div>
        <div className="stage-container">
          <div className="exper_card_container">
            {experiences.map((experience, index) => (
              <ExperienceCard
                key={`experience-${index}`}
                index={index}
                {...experience}
              />
            ))}
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
}

export default Experience;
