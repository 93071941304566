import { useEffect, useState, useRef } from 'react';
import Loader from 'react-loaders';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import AnimatedLetters from '../AnimatedLetters';
import emailjs from 'emailjs-com';
import './index.scss';

const Contact = () => {
  const form = useRef();
  const [letterClass, setLetterClass] = useState('text-animate');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_veddnmx', 'template_74daohb', form.current, 'YaMenwRBpbdGB9Q9r')
      .then(
        (result) => {
          console.log('Success:', result.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log('Error:', error.text);
        }
      );
  };

  if (isSubmitted) {
    return (
      <div style={styles.heading}>
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={[...'Will get back to you :)']}
            idx={15}
          />
        </h1>
      </div>
    );
  }

  const position = [18.4972936, 73.9188192];
  
  const calExperience = () => {
    const startDate = new Date(2020, 7); // August 2020 (Month index starts from 0)
    const currentDate = new Date();
  
    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
  
    if (months < 0) {
      years--; // Reduce one year if the current month is before August
      months += 12;
    }
  
    return months > 0 ? `${years} years ${months} months` : `${years} years`;
  };
  
  
  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={[...'Contact me']}
              idx={15}
            />
          </h1>
          <p>
        Seasoned Frontend Developer with over {calExperience()} of experience building scalable and high-performance web applications.
        Proven track record of leading the development of complex React-based applications, driving efficiency and maintainability.
        Deep expertise in modern JavaScript frameworks, state management, and front-end architecture.
        Passionate about performance optimization, accessibility, and delivering seamless user experiences.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="from_name" required />
                </li>
                <li className="half">
                  <input placeholder="Email" type="email" name="from_email" required />
                </li>
                <li>
                  <textarea placeholder="Message" name="message" required></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

const styles = {
  heading: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '20px',
    opacity: '1',
    color: 'azure',
  },
};

export default Contact;